import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { TrackByPath, TrackMenuExitButton } from './SiteHeaderTracking';
const AppStateContext = createContext();

const AppStateProvider = ({ children }) => {
  const hasMounted = useRef(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [searchProducts, setSearchProducts] = useState([]);
  const [menuLevel, setMenuLevel] = useState(1);

  const [selectedLinkSection, setSelectedLinkSection] = useState();
  const [selectedProductSection, setSelectedProductSection] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedLink, setSelectedLink] = useState();

  useEffect(() => {
    if (showSearch && isMenuVisible) setIsMenuVisible(false);
  }, [showSearch]);

  useEffect(() => {
    if (!selectedMenu) return;
    let secondLevelTitle;
    if (selectedLinkSection) secondLevelTitle = selectedLinkSection.EnglishTitle;
    else if (selectedProductSection) secondLevelTitle = selectedProductSection.EnglishTitle;

    TrackByPath(selectedMenu?.EnglishTitle, secondLevelTitle, selectedCategory?.EnglishTitle, selectedLink);
  }, [selectedMenu, selectedLinkSection, selectedProductSection, selectedCategory, selectedLink]);

  useEffect(() => {
    if (!isMenuVisible) {
      setSelectedMenu(null);
      setMenuLevel(1);
    }
    document.body.classList.toggle('overflow-hidden', isMenuVisible);

    if (!isMenuVisible && hasMounted.current === true) TrackMenuExitButton();

    hasMounted.current = true;
    if (isMenuVisible && showSearch) setShowSearch(false);
  }, [isMenuVisible]);

  return (
    <AppStateContext.Provider
      value={{
        selectedMenu,
        setSelectedMenu,
        isMenuVisible,
        setIsMenuVisible,
        showSearch,
        setShowSearch,
        searchProducts,
        setSearchProducts,
        menuLevel,
        setMenuLevel,
        selectedLinkSection,
        setSelectedLinkSection,
        selectedProductSection,
        setSelectedProductSection,
        selectedCategory,
        setSelectedCategory,
        selectedLink,
        setSelectedLink,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

const useAppState = () => {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error('useAppState must be used within an AppStateProvider');
  }
  return context;
};

export { AppStateProvider, useAppState };
