import { KlevuRecord } from '@klevu/core';
import React from 'react';
import { getUrl } from './Helpers/Helpers';

type ProductItemProps = {
  product: KlevuRecord;
  onClick?: () => void;
};

export const ProductItem = ({ product, onClick }: ProductItemProps) => {
  const formatPrice = (price: string, currency: string): string => {
    let numberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    });

    return numberFormat.format(parseFloat(price));
  };

  return (
    <article onClick={onClick}>
      <a href={getUrl(product.url)}>
        <div className="quick_search__product">
          <div className="product__imageContainer">
            {product.image.split(',')[0] && <img src={product.image.split(',')[0]} alt={product.displaytitle} />}
          </div>
          <div className="product__text mt-3 d-flex align-items-center">
            <div>
              <p className="mb-2 heading5 text-color--gray80">{product.displaytitle}</p>

              {/*Don't show the if it is the placeholder value, '---'*/}
              {product.name !== '---' && <p className="mb-3 paragraph-s text-color--gray60">{product.name}</p>}
              {!product.salePrice.startsWith('0') && (
                <p className="heading6 text-color--gray80">{formatPrice(product.salePrice, product.currency)}</p>
              )}
            </div>
          </div>
        </div>
      </a>
    </article>
  );
};
