import React from "react"
import { NavigationTrackingType } from "./NavigationTrackingType";
import { TrackByPath } from "./SiteHeaderTracking";

export const Logo = ({ }) => {
    const url = '/' + _THULEDATA.SitecoreLanguage?.toLowerCase() + '/';
    const isCaseLogic = _THULEDATA.SitecoreSiteName?.toLowerCase() === 'caselogic';

    return <a onClick={() => TrackByPath(NavigationTrackingType.LOGO)} className="logo logotype-link lh-1" aria-label="Home" href={url}>
        <i className={isCaseLogic ? 'logotype-cl' : 'logotype'}></i>
    </a>
}