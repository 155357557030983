import React, { useState, useEffect, useRef } from 'react';
// import AddToCartButton from '/Foundation/React/code/Components/Extranet/AddToCartButtonReact';
import { useAppState } from './AppStateContext';

export const SearchOverlay = () => {
  const {
    searchProducts,
    showSearch
  } = useAppState();

  if(!showSearch || !searchProducts?.length > 0)
    return null;

  const siteHeaderConfiguration = _THULEDATA._siteHeader;
  const productsText = siteHeaderConfiguration.Translations?.SearchProducts;
  const isExtranet = window._THULEDATA.isExtranet;

  const showSearchProduct = () =>
    searchProducts.map((item, i) => (
      <article key={i}>
        <a href={item.Link}>
          <div className={'search__product ' + (isExtranet ? 'extranet-search-product' : 'd-flex ')}>
            <div className="product__imageContainer">
              <img src={item.ProductImage} alt={item.Name} />
            </div>

            <div className="product__text mt-3 d-flex align-items-center">
              <div>
                {isExtranet && (
                  <div className="product__text">
                    <p className="paragraph--s mt-0 mb-5 text-color--gray60">{item.ErpOutdoor}</p>
                  </div>
                )}
                <p className="mb-2 navigationHeading text-color--gray80">{item.Name}</p>
                {item.Webtitle2 && <p className="paragraph-s text-color--gray60">{item.Webtitle2}</p>}
              </div>
            </div>
            {/* {isExtranet && (
              <AddToCartButton
                className="btn btn-sm btn-block thule-btn-shop add-to-cart-btn overflow-hidden"
                productData={item}
                productId={item.ErpOutdoor}
              />
            )} */}
          </div>
        </a>
      </article>
    ));

  // const clearSearch = () => {
  //   setSearchInput('');
  //   setSearchProducts([]);
  //   inputReference.current.focus();
  // };
  // const onSearchBtnClick = (e) => {
  //   e.preventDefault();
  //   if (searchInput.length === 0) return;
  //   const searchUrl = searchLandingPageURL + '?search=' + searchInput;
  //   return (window.location = searchUrl);
  // };

  return (
    <div className="searchOverlay">
      <div className="search__border"></div>
      {searchProducts.length > 0 && (
        <section className="search__result--product offset-xl-1 col-xl-10 col-xxl-6 offset-xxl-3 mt-8">
          <p className="paragraph--s text-color--gray60 mb-6">{productsText}</p>
          <div className="d-lg-flex">{showSearchProduct()}</div>
        </section>
      )}
      <div className="searchOverlayDrop d-none d-lg-block"></div>
    </div>
  );
};