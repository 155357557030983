import React from "react";
import { useAppState } from "./AppStateContext";

export const LinkSectionList = ({ menuItem, index }) => {
    if (!menuItem || menuItem.HasProductSection || menuItem.LinkSections?.length > 0 != true)
        return null;

    const {
        selectedMenu,
        menuLevel,
        setMenuLevel,
        setSelectedLinkSection,
        setSelectedLink
    } = useAppState();

    const bottomLinks = menuItem.LinkSections.filter((linkSection) => linkSection.PlaceLinkInBottom);
    const displayLinksWithImages = menuItem.LinkSections.filter((x) => x.ShowLinkImage).length > 0;
    const displayClass = selectedMenu == menuItem && menuLevel === 2 ? "" : "d-none";

    const LinkSectionItem = ({ linkSection }) => {
        if (!linkSection?.Link)
            return null;

        if (linkSection.PlaceLinkInBottom)
            return null;

        if (linkSection.ShowLinkImage) {
            return <a onClick={() => setSelectedLink(linkSection.EnglishTitle)} href={linkSection.Link}>
                <div className="overflow-hidden aspect-ratio-1x1 mb-2"><img loading="lazy" src={linkSection.ImageUrl} alt={linkSection.ImageAlt} /></div>
                <span className="navigationHeading">{linkSection.Title}</span>
            </a>
        }

        return <a onClick={() => setSelectedLink(linkSection.EnglishTitle)} href={linkSection.Link} className="btn navigationItem navigationParagraph">{linkSection.Title}</a>;
    }

    return <><ul className={`linkSection ${displayLinksWithImages ? "images" : ""} ${displayClass}`}>
        {menuItem.LinkSections.map((linkSection, i) =>
            <li className={`${selectedMenu == menuItem ? "" : "d-none"}`} key={i}>
                {linkSection.SubLinks?.length > 0 ? <button onClick={() => { setSelectedLinkSection(linkSection); setMenuLevel(3) }} className={`btn navigationItem`}>
                    <span className="navigationHeading">{linkSection.Title}</span>
                    <i className="le-icon-chevron rot-180"></i>
                </button> : <LinkSectionItem linkSection={linkSection} />}
            </li>
        )}
    </ul>

        {bottomLinks?.length > 0 &&
            <ul className={`navigationBottom ${displayClass} bg-color--${index}`}>
                {bottomLinks.map((linkSection, i) =>
                    <li key={i}><a onClick={() => setSelectedLink(linkSection.EnglishTitle)} href={linkSection.Link} className={`navigationItem navigationParagraph`}>{linkSection.Title}</a></li>
                )}
            </ul>}
    </>
}