import React, { useEffect } from 'react';
import { LinkSectionList } from './LinkSectionList';
import { ProductSectionList } from './ProductSectionList';
import { Promotion } from './Promotion';
import { TrackByPath } from './SiteHeaderTracking';
import { useAppState } from './AppStateContext';

export const Navigation = ({ items }) => {
  if (!items?.length > 0) return null;

  const {
    setIsMenuVisible,
    selectedMenu,
    setSelectedMenu,
    menuLevel,
    setMenuLevel,
    selectedLinkSection,
    setSelectedLinkSection,
    selectedProductSection,
    setSelectedProductSection,
    selectedCategory,
    setSelectedCategory,
    setSelectedLink,
  } = useAppState();

  const siteHeaderConfiguration = _THULEDATA._siteHeader;

  useEffect(() => {
    setSelectedProductSection(null);
    setSelectedLinkSection(null);
    setSelectedCategory(null);
  }, [selectedMenu]);

  const BackAndCloseButton = () => {
    const menuConfig = {
      2: { title: selectedMenu?.Title },
      3: selectedProductSection
        ? { setFunction: setSelectedProductSection, title: selectedProductSection?.Name }
        : { setFunction: setSelectedLinkSection, title: selectedLinkSection?.Title },
      4: { setFunction: setSelectedCategory, title: selectedCategory?.Name },
    };

    const { setFunction, title } = menuConfig[menuLevel] || {};
    if (!title) return null;

    const showDesktopProductSectionBackButton = menuLevel == 4 && selectedProductSection;

    return (
      <div className={`sitePadding navigationItem navigationActionBar menuLevel-${menuLevel}`}>
        <button
          onClick={() => {
            setFunction && setFunction(null);
            setMenuLevel(menuLevel - 1);
          }}
          className={`btn ${showDesktopProductSectionBackButton && 'd-lg-none'}`}
        >
          <i className="le-icon-chevron me-4"></i>
          <span className="navigationHeading">{title}</span>
        </button>
        {showDesktopProductSectionBackButton && (
          <button
            onClick={() => {
              setSelectedCategory(null);
              setSelectedProductSection(null);
              setMenuLevel(2);
            }}
            className={`btn d-none d-lg-flex`}
          >
            <i className="le-icon-chevron me-4"></i>
            <span className="navigationHeading">{selectedProductSection.Name}</span>
          </button>
        )}
        <button
          onClick={() => {
            setIsMenuVisible(false);
          }}
          className="btn d-none d-lg-block"
        >
          <i className="le-icon-x"></i>
          <span className="sr-only">Close menu</span>
        </button>
      </div>
    );
  };

  const openLanguageSelector = () => {
    if (!_THULEDATA.isExtranet) {
      document.querySelector('button[data-bs-target="#marketSelector"]')?.click();
      setSelectedLink('Language selector');
    }
  };

  return (
    <nav className={`navigation menuLevel-${menuLevel}`}>
      <BackAndCloseButton />

      <ul className={`d-lg-none menuFirstLevel ${menuLevel === 1 ? '' : 'siteHeader_hidden'}`}>
        {items.map((item, i) => (
          <li key={i}>
            {item.Link ? (
              <a
                onClick={() => {
                  TrackByPath(item.EnglishTitle);
                }}
                className={`btn navigationItem navigationHeading`}
                href={item.Link}
              >
                {item.Title}
              </a>
            ) : (
              <button
                onClick={() => {
                  setSelectedMenu(item);
                  setMenuLevel(2);
                }}
                className={`btn navigationItem`}
              >
                <span className="navigationHeading">{item.Title}</span>
                <i className="le-icon-chevron rot-180"></i>
              </button>
            )}
          </li>
        ))}
      </ul>

      <ul className={`d-lg-none menuFirstLevelBottom ${menuLevel === 1 ? '' : 'd-none'}`}>
        <div className="navigationBorder"></div>
        {siteHeaderConfiguration.MyAccountPageLink?.Url && (
          <li>
            <i className="le-icon-user"></i>
            <a
              onClick={() => {
                setSelectedLink('Login');
              }}
              className="navigationItem paragraph--s navigationParagraph"
              href={siteHeaderConfiguration.MyAccountPageLink.Url}
            >
              {siteHeaderConfiguration.MyAccountPageLink.Text}
            </a>
          </li>
        )}
        {siteHeaderConfiguration.DealerLocatorPageLink?.Url && (
          <li>
            <i className="le-icon-map-pin"></i>
            <a
              onClick={() => {
                setSelectedLink('Stores');
              }}
              className="navigationItem paragraph--s navigationParagraph"
              href={siteHeaderConfiguration.DealerLocatorPageLink.Url}
            >
              {siteHeaderConfiguration.DealerLocatorPageLink.Text}
            </a>
          </li>
        )}
        {!_THULEDATA.isExtranet && (
          <li>
            <i className="le-icon-globe"></i>
            <button className="btn navigationItem" onClick={openLanguageSelector}>
              <span className="navigationParagraph">{siteHeaderConfiguration.MarketSelectorIcon?.Title}</span>
            </button>
          </li>
        )}
      </ul>
      {items.map((menuFirstLevelItem, i) => (
        <React.Fragment key={i}>
          <ProductSectionList menuItem={menuFirstLevelItem} />
          <LinkSectionList index={i} menuItem={menuFirstLevelItem} />
        </React.Fragment>
      ))}

      <ul className={`menuTertiaryLevel p-0 ${menuLevel === 3 || menuLevel === 4 ? '' : 'siteHeader_hidden'}`}>
        {items.map((menuFirstLevelItem, i) => (
          <React.Fragment key={i}>
            {menuFirstLevelItem?.ProductSections?.length > 0 &&
              menuFirstLevelItem.ProductSections.map((productSection) =>
                productSection.Categories?.map((category, i) => {
                  return (
                    <li
                      key={i}
                      className={`${selectedProductSection == productSection ? '' : 'd-none'} ${selectedCategory == category ? 'active' : ''}`}
                    >
                      {siteHeaderConfiguration.HideSubcategoriesInMenu ? (
                        <a
                          onClick={() => setSelectedLink(category.EnglishTitle)}
                          className={`btn navigationItem navigationParagraph`}
                          href={category.Link}
                        >
                          {category.Name}
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            if (selectedCategory == category && menuLevel === 4) {
                              setMenuLevel(3);
                              setSelectedCategory(null);
                            } else {
                              setSelectedCategory(category);
                              setMenuLevel(4);
                            }
                          }}
                          key={i}
                          className={`btn navigationItem navigationParagraph`}
                        >
                          <span className="navigationParagraph">{category.Name}</span>
                          <i className="le-icon-chevron rot-180"></i>
                        </button>
                      )}
                    </li>
                  );
                })
              )}
            {menuFirstLevelItem.LinkSections?.length > 0 &&
              menuFirstLevelItem.LinkSections.map(
                (linkSection) =>
                  linkSection.SubLinks?.length > 0 &&
                  linkSection.SubLinks.map((subLink, i) => (
                    <li key={i}>
                      <a
                        onClick={() => setSelectedLink(subLink.EnglishTitle)}
                        className={`btn navigationItem navigationParagraph ${selectedLinkSection == linkSection ? '' : 'd-none'}`}
                        href={subLink.Link}
                      >
                        {subLink.Title}
                      </a>
                    </li>
                  ))
              )}
          </React.Fragment>
        ))}
      </ul>

      <ul className={`menuQuaternaryLevel bg-color--0 navigationBottom ${menuLevel === 4 ? '' : 'siteHeader_hidden'}`}>
        {items.map(
          (menuFirstLevelItem) =>
            menuFirstLevelItem?.ProductSections?.length > 0 &&
            menuFirstLevelItem.ProductSections.map((productSection) =>
              productSection.Categories?.map((category, i) => {
                return (
                  <React.Fragment key={i}>
                    <li className={`${selectedCategory == category ? '' : 'd-none'}`}>
                      <a
                        onClick={() => setSelectedLink(category.EnglishDiscoverAllText)}
                        href={category.Link}
                        className={`navigationItem navigationHeading`}
                      >
                        {category.DiscoverAllText}
                        <i className="le-icon-arrow rot-180"></i>
                      </a>
                    </li>
                    {category?.SubCategories?.length > 0 &&
                      category?.SubCategories.map((subCategory, i) => (
                        <li key={i}>
                          <a
                            onClick={() => setSelectedLink(subCategory.EnglishTitle)}
                            className={`navigationItem navigationParagraph ${selectedCategory == category ? '' : 'd-none'}`}
                            href={subCategory.Link}
                          >
                            {subCategory.Name}
                          </a>
                        </li>
                      ))}
                  </React.Fragment>
                );
              })
            )
        )}
      </ul>

      {items.map(
        (menuFirstLevelItem, i) =>
          menuFirstLevelItem?.PromotionField && <Promotion key={i} promotion={menuFirstLevelItem.PromotionField} />
      )}
      {items.map(
        (menuFirstLevelItem) =>
          menuFirstLevelItem?.ProductSections?.length > 0 &&
          menuFirstLevelItem.ProductSections.map(
            (productSection, i) =>
              productSection?.PromotionField && <Promotion key={i} promotion={productSection.PromotionField} />
          )
      )}
    </nav>
  );
};
