import React from 'react';
import { useAppState } from './AppStateContext';

export const ProductSectionList = ({ menuItem }) => {
  if (menuItem?.ProductSections?.length > 0 != true) return null;

  const { selectedMenu, menuLevel, setMenuLevel, selectedProductSection, setSelectedLink, setSelectedProductSection } =
    useAppState();

  const displayClass =
    selectedMenu == menuItem && !selectedProductSection && menuLevel === 2 ? '' : 'siteHeader_hidden';
  return (
    <>
      <ul className={`menuSecondaryLevel ${displayClass}`}>
        {menuItem.ProductSections.map((productSection, i) => (
          <li key={i}>
            {productSection.Url ? (
              <a
                onClick={() => setSelectedLink(productSection.EnglishTitle)}
                href={productSection.Url}
                className={`navigationItem navigationHeading`}
              >
                {productSection.Name}
              </a>
            ) : (
              <button
                onClick={() => {
                  setSelectedProductSection(productSection);
                  setMenuLevel(3);
                }}
                key={i}
                className={`btn navigationItem`}
              >
                <span className="navigationHeading">{productSection.Name}</span>
                <i className="le-icon-chevron rot-180"></i>
              </button>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};
