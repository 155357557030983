import React, { useEffect, useState, useRef } from "react";
import { NavigationTrackingType } from "./NavigationTrackingType";
import { TrackByPath } from "./SiteHeaderTracking";
import { useAppState } from "./AppStateContext";

export const QuickLinks = () => {
    const {
        isMenuVisible,
        setIsMenuVisible,
        showSearch,
        setShowSearch
    } = useAppState();

    const config = _THULEDATA._siteHeader;
    const [cartQuantity, setCartQuantity] = useState();
    const observedRef = useRef(null);

    const loadCartQuantity = () => {
        let apiUrl = config.CartQuantityApiUrl;
        if (_THULEDATA.isExtranet)
            apiUrl += localStorage.getItem('cartIdentifier');

        if (!_THULEDATA.isB2CEnabled && !_THULEDATA.isExtranet || !apiUrl) return;

        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                setCartQuantity(data && data > 0 ? data : null);
            });
    }

    useEffect(() => {
        loadCartQuantity();

        const element = observedRef.current;
        if (!element) return;
        const observer = new MutationObserver(() => {
            loadCartQuantity();
        });

        observer.observe(element, {
            attributes: true,
        });
        return () => observer.disconnect();
    }, []);

    const CartLink = () => {
        return (
            (config.ShoppingCartPageLink.Url && (_THULEDATA.isB2CEnabled || _THULEDATA.isExtranet)) && (
                <a onClick={() => TrackByPath(NavigationTrackingType.CART)}
                    href={config.ShoppingCartPageLink?.Url}
                    className="btn quickLinks__btn"
                >
                    <span className="cart-counter">{cartQuantity}</span>
                    <i className="le-icon-shopping-cart" />
                    <span className="sr-only">Go to cart</span>
                </a>
            )
        );
    };

    const MyAccountLink = () => {
        return (
            (_THULEDATA.isB2CEnabled || _THULEDATA.isExtranet) && (
                <a
                    onClick={() => TrackByPath(NavigationTrackingType.LOGIN)}
                    href={config.MyAccountPageLink?.Url}
                    className="btn quickLinks__btn d-none d-lg-block"
                >
                    <i className="le-icon-user"></i>
                    <span className="sr-only">My account</span>
                </a>
            )
        );
    };

    const ProductFavoritesLink = () => {
        if (!config.ProductFavoritesPageLink?.Url)
            return null;

        return <a onClick={() => TrackByPath(NavigationTrackingType.LIKED_PRODUCTS)} className="quickLinks__btn" href={config.ProductFavoritesPageLink.Url}><i className="le-icon-heart"></i>
            <span className="sr-only">{config.ProductFavoritesPageLink.Text}</span></a>
    }

    const ToggleMenuButton = () => {
        const trackToggle = () => {
            setIsMenuVisible(!isMenuVisible)
            if (!isMenuVisible) {
                TrackByPath("HamburgerMenu"); //exception because the mobile menu has an additional level
            }
        }
        return (
            <button className="btn quickLinks__btn pe-0 d-lg-none" onClick={trackToggle}>
                <i className={`le-icon-${isMenuVisible ? 'x' : 'menu'}`} />
                <span className="sr-only">Toggle menu</span>
            </button>
        );
    };

    const CloseSearchButton = () => {
        return (
            <button className="btn exit quickLinks__btn pe-0 d-lg-none" onClick={() => setShowSearch(!showSearch)}>
                <i className={`le-icon-x`} />
                <span className="sr-only">Close search</span>
            </button>
        );
    }

    return <div className={`${showSearch ? "hide" : ""} quickLinks`}>
        <input type="hidden" data-refresh="" id="siteHeader_cartRefresh" ref={observedRef} />
        <ProductFavoritesLink />
        <MyAccountLink />
        <CartLink />
        <ToggleMenuButton />
        {showSearch && <CloseSearchButton />}
    </div>
}