import React, { useEffect, useState } from 'react';
import './Promotion.less';
import { useAppState } from './AppStateContext';

export const Promotion = ({ promotion }) => {
  if (!promotion) return null;

  const { selectedMenu, setSelectedLink, selectedProductSection } = useAppState();

  const [activePromotion, setActivePromotion] = useState();

  useEffect(() => {
    setActivePromotion(selectedProductSection ? selectedProductSection?.PromotionField : selectedMenu?.PromotionField);
  }, [selectedMenu, selectedProductSection]);

  return (
    <div className={`promotion ${activePromotion == promotion ? 'siteHeader_visible' : 'siteHeader_hidden'}`}>
      {promotion.ImageURL && (
        <a
          onClick={() => {
            setSelectedLink('Promotion');
          }}
          className="promotion__image"
          href={promotion.PromotionAreaLinkURL}
        >
          <img src={promotion.ImageURL} alt={promotion.ImageAltText} />
          {promotion.Header && (
            <p className="navigationHeading sitePadding promotion__header">
              <span>{promotion.Header}</span>
              <i className="le-icon-arrow rot-180"></i>
            </p>
          )}
          <div className="shadowOverlay"></div>
        </a>
      )}
      {promotion.LinksMultiselectField?.length > 0 && (
        <ul className="mt-9 pb-20">
          {promotion.LinksMultiselectField.map(
            (link, keyIndex) =>
              link.DisplayName && (
                <a
                  onClick={() => {
                    setSelectedLink(link.EnglishTitle);
                  }}
                  className="btn navigationItem skipHover paragraph--s text-color--gray80"
                  href={link.Link}
                  key={keyIndex}
                >
                  {link.DisplayName}
                </a>
              )
          )}
        </ul>
      )}
    </div>
  );
};
