import { useLayoutEffect, useState } from 'react';

export const useOnResize = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    let timer;

    setWidth(window.innerWidth);

    const updateWidth = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }, 100);
    };

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  });

  return [width, height];
};
