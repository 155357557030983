import React, { KeyboardEvent, ChangeEvent, useEffect, createRef } from 'react';

type SearchFieldProps = {
  id: string;
  placeholder: string;
  value: string;
  label: string;
  onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  clear: () => void;
  close: () => void;
  showQuickSearch: boolean;
};

export const SearchField = ({
  id,
  placeholder,
  value,
  label,
  onChange,
  onFocus,
  onKeyUp,
  clear,
  close,
  showQuickSearch,
}: SearchFieldProps) => {
  const searchFieldRef = createRef<HTMLInputElement>();

  const translations = window._THULEDATA._siteHeader.Translations;

  useEffect(() => {
    if (showQuickSearch === true) searchFieldRef.current?.focus();
  }, [showQuickSearch]);

  return (
    <div className="thule-input">
      <label htmlFor={id} className="visually-hidden">
        {label}
      </label>
      <div className="position-relative">
        <i className="leading-icon le-icon-search"></i>
        <input
          tabIndex={-1}
          type="text"
          placeholder={placeholder}
          className="input px-9 px-9"
          id={id}
          name={id}
          onChange={onChange}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          value={value}
          enterKeyHint="search"
          autoComplete="off"
          ref={searchFieldRef}
        />
        <p className={`clear d-none ${value ? 'd-lg-block' : ''}`} onClick={clear}>
          {translations?.Clear}
        </p>
        <i className={`trailing-icon close le-icon-x d-none d-lg-block`} onClick={close}></i>
        <i
          className={`trailing-icon close le-icon-x d-lg-none`}
          onClick={() => {
            close();
            clear();
          }}
        ></i>
      </div>
    </div>
  );
};
