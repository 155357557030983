import React, { KeyboardEvent, ChangeEvent, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  KlevuFetch,
  KlevuKMCSettings,
  KlevuResultEvent,
  KlevuTypeOfRecord,
  search,
  suggestions,
  sendSearchEvent,
  KlevuRecord,
  KMCRootObject,
  KlevuConfig,
  KlevuSearchOptions,
} from '@klevu/core';

import { Logo } from '../Logo';
import { SearchField } from './SearchField';
import { ProductItem } from './ProductItem';
import { KlevuMaps, KlevuUrlMap } from './Interfaces/interfaces';
import { TrackQuickSearchExitButton } from '../SiteHeaderTracking';
import { getUrl } from './Helpers/Helpers';

import { useOnResize } from '../../../../../../../../Foundation/React/code/Hooks/UseOnResize';

let clickManager: KlevuResultEvent['searchClickEvent'];

export const QuickSearch = () => {
  const [showQuickSearch, setShowQuickSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [products, setProducts] = useState<KlevuRecord[]>([]);
  const [sugs, setSuggestions] = useState<string[]>([]);
  const [categories, setCategories] = useState<KlevuRecord[]>([]);
  const [kmcSettings, setKmcSettings] = useState<KMCRootObject>();
  const [debounceSearchPhrase] = useDebounce(searchTerm, 500);
  const [mappings, setMappings] = useState<KlevuMaps>();
  const [heightDifference, setHeightDifference] = useState<number>(0);

  const [width, height] = useOnResize();
  const translations = window._THULEDATA._siteHeader.Translations;

  useEffect(() => {
    KlevuConfig.init({
      url: window._KlevuConfig.url,
      apiKey: window._KlevuConfig.apiKey,
    });

    fetchKMCSettings();
    fetchMappings();
  }, []);

  useEffect(() => {
    let setMaxHeight = window.innerHeight < 885 && window.innerWidth < 1400 && window.innerWidth > 991;
    if (setMaxHeight) {
      setHeightDifference(885 - window.innerHeight);
    } else {
      setHeightDifference(0);
    }
  }, [width, height]);

  useEffect(() => {
    if (!debounceSearchPhrase || debounceSearchPhrase.length <= 2) return;

    searchKlevu(debounceSearchPhrase);
  }, [debounceSearchPhrase]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) body.classList.toggle('overflow-hidden', showQuickSearch);

    if (showQuickSearch) document.body.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.body.removeEventListener('touchmove', handleTouchMove);
    };
  }, [showQuickSearch]);

  const fetchMappings = async (): Promise<void> => {
    const url = `https://js.klevu.com/klevu-js-v1/klevu-js-api/${window._KlevuConfig.apiKey}-maps.json`;

    const response = await fetch(url);
    const data = await response.json();
    const mappings = data as KlevuMaps;
    setMappings(mappings);
  };

  const handleTouchMove = (e: any) => {
    if (document.activeElement?.id === 'quick-search-input') {
      e.preventDefault();

      if (e.target.closest('#quickSearchResults') !== null) {
        console.log('scrolling within search results');
        (document.activeElement as HTMLInputElement)?.blur();
      }
    }
  };

  const searchKlevu = async (query: string): Promise<void> => {
    if (searchTerm.length < 3) {
      setProducts([]);
      setSuggestions([]);
      return;
    }

    const searchId = 'products' + new Date().getTime();
    const caregoryId = 'categories' + new Date().getTime();
    const suggestionsId = 'suggestions' + new Date().getTime();

    const searchOptions: KlevuSearchOptions = {
      id: searchId,
      limit: 4,
      typeOfRecords: [KlevuTypeOfRecord.Product],
      groupBy: 'id',
      visibilityGroupID: window._THULEDATA.SitecoreLanguage,
      priceFieldSuffix: `${window._THULEDATA.currencyCode}-${window._THULEDATA.SitecoreLanguage}`,
    };

    const categoryOptions = {
      id: caregoryId,
      limit: 5,
      typeOfRecords: [KlevuTypeOfRecord.Category],
    };

    const result = await KlevuFetch(
      search(query, searchOptions, ...[sendSearchEvent()]),
      search(query, categoryOptions),
      suggestions(query, {
        id: suggestionsId,
      })
    );

    const searchResult = result.queriesById(searchId);
    clickManager = searchResult.searchClickEvent?.bind(searchResult);

    setProducts(searchResult?.records ?? []);
    setSuggestions(result.suggestionsById(suggestionsId)?.suggestions.map((i) => i.suggest) ?? []);
    setCategories(result.queriesById(caregoryId)?.records ?? []);
  };

  const handleOnFocus = (): void => {
    if (debounceSearchPhrase) {
      if (debounceSearchPhrase != searchTerm) searchKlevu(debounceSearchPhrase);
    }
  };

  const fetchKMCSettings = async (): Promise<void> => {
    const settings = await KlevuKMCSettings();
    setKmcSettings(settings.root);
  };

  const handleSearch = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key !== 'Enter') return;

    const matchingMapping = mappings?.klevu_keywordUrlMap?.filter((m: KlevuUrlMap) => m.keywords.includes(searchTerm));

    if (matchingMapping && matchingMapping.length > 0) {
      window.location.href = matchingMapping[0].url;
    } else {
      window.location.href = `${window._THULEDATA._siteHeader.SearchPageLink.Url}?search=${searchTerm}`;
    }
  };

  return (
    <>
      <div className="quick-search">
        <a className="search-btn" onClick={() => setShowQuickSearch(!showQuickSearch)}>
          <i className="le-icon-search ms-3"></i>
          <span className="sr-only">Search</span>
        </a>
      </div>
      {showQuickSearch && (
        <div
          className="quick-search-overlay__backdrop"
          onClick={() => {
            TrackQuickSearchExitButton();
            setShowQuickSearch(false);
          }}
        ></div>
      )}
      <div className={`quick-search-overlay pt-lg-5 ${showQuickSearch ? '' : 'd-none'}`}>
        <div className="quick-search-bar px-0 px-lg-6 px-xxl-9">
          <Logo />
          <SearchField
            id="quick-search-input"
            label={translations?.Search}
            placeholder={translations?.Search}
            value={searchTerm}
            onKeyUp={handleSearch}
            onFocus={handleOnFocus}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
            }}
            clear={() => {
              setSearchTerm('');
              setProducts([]);
              setSuggestions([]);
            }}
            close={() => {
              TrackQuickSearchExitButton();
              setShowQuickSearch(false);
              setSearchTerm('');
              setProducts([]);
              setSuggestions([]);
            }}
            showQuickSearch={showQuickSearch}
          />
        </div>
        <div className="quick-search-results-container">
          <div className="quick-search-results px-6 px-lg-0 px-xxl-9 my-7 mt-lg-11" id="quickSearchResults">
            <div className="quick-search-links">
              {!searchTerm && kmcSettings && kmcSettings?.klevu_webstorePopularTerms?.length > 0 && (
                <div className="popular-section pb-8 pb-lg-9">
                  <p className="paragraph--s section-sub-header">{translations?.QuickLinks}</p>
                  <ul>
                    {kmcSettings?.klevu_webstorePopularTerms?.map((s, i) => (
                      <li
                        key={i}
                        onClick={() => {
                          window.location.href = `${window._THULEDATA._siteHeader.SearchPageLink.Url}?search=${s.replace(/<[^>]*>?/gm, '')}`;
                        }}
                      >
                        {s}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {searchTerm && sugs.length > 0 && (
                <div className="suggestions-section">
                  <p className="paragraph--s section-sub-header">{translations?.Suggestions}</p>
                  <ul>
                    {sugs.map((s, i) => (
                      <li
                        key={i}
                        dangerouslySetInnerHTML={{ __html: s }}
                        onClick={() => {
                          window.location.href = `${window._THULEDATA._siteHeader.SearchPageLink.Url}?search=${s.replace(/<[^>]*>?/gm, '')}`;
                        }}
                      ></li>
                    ))}
                  </ul>
                </div>
              )}
              {searchTerm && categories.length > 0 && (
                <div className="category-section">
                  <p className="paragraph--s section-sub-header">{translations?.Categories}</p>
                  <ul>
                    {categories.map((s, i) => (
                      <li
                        key={i}
                        dangerouslySetInnerHTML={{ __html: s.name }}
                        onClick={() => {
                          window.location.href = getUrl(s.url);
                        }}
                      ></li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {products.length > 0 && searchTerm && (
              <div className="products-section">
                <p className="paragraph--s section-sub-header">{translations.SearchProducts}</p>
                <div
                  className="products d-flex"
                  style={{ maxHeight: heightDifference > 0 ? `calc(100% - 31px - ${heightDifference}px)` : undefined }}
                >
                  {products.map((product, i) => (
                    <ProductItem
                      key={i}
                      product={product}
                      onClick={() => {
                        if (clickManager) clickManager({ productId: product.id });
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
