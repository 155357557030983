import React, { useRef, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Logo } from './Logo';
import { PrimaryDesktopNavigation } from './PrimaryDesktopNavigation';
import { QuickLinks } from './QuickLinks';
import { SearchOverlay } from './SearchOverlay';
import { Search } from './Search';
import { Navigation } from './Navigation';
import { useDebouncedCallback } from 'use-debounce';
import { AppStateProvider, useAppState } from './AppStateContext';
import { QuickSearch } from './Klevu/QuickSearch';

const SiteHeader = () => {
  const siteHeaderConfiguration = _THULEDATA._siteHeader;
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const { isMenuVisible, setIsMenuVisible, setShowSearch, menuLevel, showQuickSearch } = useAppState();

  const wrapper = useRef();

  const enableKlevu = window._THULEDATA._siteHeader.EnableKlevu;

  const handleClickOutside = (event) => {
    if (
      (event.target.closest('.searchOverlayDrop') ||
        event.target.closest('.backdrop') ||
        !wrapper.current.contains(event.target)) &&
      !document.querySelector('.popup.show')
    ) {
      setShowSearch(false);
      setIsMenuVisible(false);
    }
  };

  useEffect(() => {
    if (document.querySelector('.hellobar-component')) wrapper.current.classList.add('hasHelloBar');

    document.addEventListener('scroll', handleScroll);
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = useDebouncedCallback(() => {
    if (showQuickSearch) return;

    const currentScrollTop = window.scrollY;

    if (Math.abs(currentScrollTop - lastScrollTop) >= 5) {
      //threshold to prevent unwanted scroll direction trigger
      const contentHeightAboveMenu = document.querySelector('.hellobar-component')?.clientHeight || 0;
      const totalHeight = wrapper.current.clientHeight + contentHeightAboveMenu;
      const isScrollDown = currentScrollTop > lastScrollTop;
      const isScrollWithinSiteHeader = currentScrollTop >= totalHeight;
      const hasSticky = wrapper.current.classList.contains('sticky');

      const offset = 150;

      if (isScrollDown || currentScrollTop <= contentHeightAboveMenu) {
        wrapper.current.classList.remove('sticky');
        document.body.classList.remove('overflow-hidden');
      } else if (currentScrollTop < lastScrollTop && isScrollWithinSiteHeader && currentScrollTop > offset) {
        wrapper.current.classList.add('sticky');
      }

      wrapper.current.classList.toggle('idle', isScrollWithinSiteHeader);

      const shouldCollapse = isScrollDown && isScrollWithinSiteHeader;
      if ((shouldCollapse && hasSticky) || !shouldCollapse)
        wrapper.current.classList.toggle('collapsed', shouldCollapse);

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    }
  }, 20);

  return (
    <div
      ref={wrapper}
      onKeyUp={(e) => {
        e.key === 'Escape' && setIsMenuVisible(false);
      }}
      className={`siteHeader_wrapper`}
    >
      <div className="layout__content thule-container">
        <div className={`siteHeader`}>
          <Logo />
          <PrimaryDesktopNavigation navigation={siteHeaderConfiguration.Navigation} />
          <div className="rightContent">
            {!enableKlevu && <Search />}
            {enableKlevu && <QuickSearch />}
            <QuickLinks />
          </div>
          <div className={`navigationOverlay menuLevel-${menuLevel} ${isMenuVisible ? 'active' : ''}`}>
            <Navigation items={siteHeaderConfiguration.Navigation} />
          </div>
          {isMenuVisible && <div className="backdrop"></div>}
        </div>
      </div>
      <SearchOverlay />
    </div>
  );
};

const root = createRoot(document.getElementById('REACT_siteHeader'));
root.render(
  <AppStateProvider>
    <SiteHeader />
  </AppStateProvider>
);
