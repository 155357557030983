import { AdobeAnalyticsUtil } from '/Foundation/Common/code/Views/Foundation/Common/AdobeAnalytics/AdobeAnalyticsUtil';

const GetDeviceParameterData = () => {
  if (window.innerWidth <= 767) return 'Mobile';

  if (window.innerWidth <= 991) return 'Tablet';

  return 'Desktop';
};

export const TrackMenuExitButton = () => {
  AdobeAnalyticsUtil.DataLayer.TrackEvent('main_menu_navigation_exit', {
    main_menu: { device: GetDeviceParameterData() },
  });
};

export const TrackByPath = (firstLevelTitle, secondLevelTitle, thirdLevelTitle, fourthLevelTitle) => {
  let path = [firstLevelTitle, secondLevelTitle, thirdLevelTitle, fourthLevelTitle].filter((x) => x).join('|');

  if (!path) return;

  const trackingData = {
    main_menu: {
      device: GetDeviceParameterData(),
      path: path,
    },
  };

  AdobeAnalyticsUtil.DataLayer.TrackEvent('main_menu_navigation', trackingData);
};

export const TrackQuickSearchExitButton = () => {
  AdobeAnalyticsUtil.DataLayer.TrackEvent('search_exit', {
    search: { device: GetDeviceParameterData() },
  });
};
