import React from 'react';
import { TrackByPath } from './SiteHeaderTracking';
import { useAppState } from './AppStateContext';

export const PrimaryDesktopNavigation = ({ navigation }) => {
  const { selectedMenu, setSelectedMenu, setMenuLevel, setIsMenuVisible } = useAppState();

  const showMenu = (navigationItem) => {
    setSelectedMenu(navigationItem);
    setIsMenuVisible(true);
    setMenuLevel(2);
  };

  return (
    <nav className="primaryDesktopNavigation d-none d-lg-flex">
      <ul>
        {navigation &&
          navigation.map((navigationItem, i) => (
            <li key={i}>
              {navigationItem?.Link ? (
                <a
                  onClick={() => {
                    TrackByPath(navigationItem.EnglishTitle);
                  }}
                  className={`navigationItem navigationHeading`}
                  href={navigationItem.Link}
                >
                  {navigationItem.Title}
                </a>
              ) : (
                <button
                  onClick={() => {
                    if (navigationItem == selectedMenu) {
                      setIsMenuVisible(false);
                    } else {
                      showMenu(navigationItem);
                    }
                  }}
                  className={`btn navigation__item ${selectedMenu == navigationItem && 'active'}`}
                >
                  <span className="navigationHeading">{navigationItem.Title}</span>
                </button>
              )}
            </li>
          ))}
      </ul>
    </nav>
  );
};
