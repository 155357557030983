import React, { useState, useEffect, useRef } from 'react';
import { useDebounce } from 'use-debounce';
import { NavigationTrackingType } from './NavigationTrackingType';
import { useAppState } from './AppStateContext';
import { TrackByPath } from './SiteHeaderTracking';

export const Search = () => {
  const { showSearch, setShowSearch, setSearchProducts, setSelectedLink } = useAppState();

  const [searchInput, setSearchInput] = useState('');
  const [debounceSearchPhrase] = useDebounce(searchInput, 100);
  const input = useRef();
  const countryCode = window._THULEDATA.SitecoreLanguage;
  const settings = _THULEDATA._siteHeader;
  const searchHitsAmount = window.innerWidth < 992 ? settings.SearchResultSizeMobile : settings.SearchResultSizeDesktop;
  const searchUrl =
    settings.SearchApiUrl +
    `?query=${searchInput}&numberOfResults=${searchHitsAmount}&language=${countryCode}&imageSize=280`;

  const runFullSearch = (e) => {
    e.preventDefault();
    if (searchInput.length === 0) return;
    const searchPageUrl = settings.SearchPageLink?.Url + '?search=' + searchInput;
    return (window.location = searchPageUrl);
  };

  useEffect(() => {
    if (showSearch) input.current.focus();
    else setSearchInput('');
  }, [showSearch]);

  useEffect(() => {
    setSearchProducts([]);
    if (!debounceSearchPhrase || debounceSearchPhrase.length <= 2) return;

    const controller = new AbortController();

    const options = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      signal: controller?.signal,
    };
    fetch(searchUrl, { ...options, credentials: 'same-origin' })
      .then((response) => response.json())
      .then((data) => {
        setShowSearch(true);
        if (data.length > 0) {
          setSearchProducts(data);
        }
      })
      .catch((e) => e);

    return () => controller.abort('New search phrase');
  }, [debounceSearchPhrase]);

  return (
    <form
      onClick={() => TrackByPath(NavigationTrackingType.SEARCH)}
      onSubmit={runFullSearch}
      className={`siteHeader_search ${showSearch && 'active'}`}
    >
      <label htmlFor="inputSearch" className="sr-only">
        {settings.Translations.Search}
      </label>
      <input
        id="inputSearch"
        value={searchInput}
        onKeyUp={(e) => {
          e.key === 'Escape' ? setShowSearch(false) : null;
        }}
        ref={input}
        placeholder={settings.Translations.Search}
        onChange={(e) => setSearchInput(e.target.value)}
        type="search"
        autoComplete="off"
      />
      <button type="button" onClick={() => setShowSearch(!showSearch)} className="btn p-0">
        <i className="le-icon-search ms-3"></i>
        <span className="sr-only">{settings.Translations.Search}</span>
      </button>
      <input className="d-none" type="submit" value={'Submit'} />
    </form>
  );
};
